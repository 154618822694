<template>
  <div class="global-trends-dashboard">
    <ai-breadcrumb />
    <div class="new-button">
      <guide class="guide-button" :disabled="true" />
    </div>
    <div class="search-content">
      <el-form :inline="true" size="small" class="search-form">
        <el-form-item class="search-form-gutter" :label="$t('globalTrend.year')">
          <el-select style="width: 88px" v-model="currentYear" @change="changeYear" filterable>
            <el-option v-for="item in yearList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('globalTrend.season')">
          <el-select style="width: 88px" v-model="currentSeason" @change="changeSeason">
            <el-option v-for="item in seasonList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('globalTrend.sex')">
          <el-select style="width: 88px" v-model="currentSex" clearable>
            <el-option v-for="item in sexList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('globalTrend.category')">
          <el-select style="width: 112px" v-model="currentCategory" clearable @change="changeCategory">
            <el-option v-for="item in categoryList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('globalTrend.item')">
          <el-select style="width: 168px" v-model="currentItem" clearable>
            <el-option v-for="item in itemList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" circle @click="fetchData"></el-button>
        </el-form-item>
        <el-form-item class="search-form-gutter">
          <el-tooltip class="item" effect="dark" content="Download Excel" placement="bottom">
            <el-button size="small" :circle="true" type="success" class="excel-dl-btn" @click="clickDownload"
              icon="el-icon-download"></el-button>
          </el-tooltip>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-col :span="8">
        <div id="item-dashboard-bar"></div>
      </el-col>
      <el-col :span="16">
        <div id="item-dashboard-pie"></div>
      </el-col>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import Guide from "@/components/Guide";
import { downloadFileAuth } from "@/utils/download-file";
import { aiApiRoot } from "@/utils/server";
import { getToken } from "@/utils/auth";
import { fetchDashboard, fetchOptionsYear, fetchOptionsSeason, fetchOptionsItem } from "@/api/global-trend";

export default {
  components: { AiBreadcrumb, Guide },
  data() {
    return {
      tableData: [],
      yearList: [],
      seasonList: [],
      sexList: ["W", "M"],
      categoryList: ["TOP", "OUTER", "BOTTOM", "DRESS"],
      itemList: [],
      currentYear: "",
      currentSeason: "",
      currentSex: "W",
      currentCategory: "DRESS",
      currentItem: "",
      currentTrendPoint: ""
    }
  },
  mounted() {
    this.fetchOptionsYear(() => {
      this.fetchData();
    });
    this.barChart = echarts.init(document.getElementById("item-dashboard-bar"));
    this.pieChart = echarts.init(document.getElementById("item-dashboard-pie"));
    window.addEventListener("resize", this.loadChart);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.loadChart);
    this.unloadChart();
  },
  methods: {
    loadChart() {
      this.barChart.resize();
      this.pieChart.resize();
    },
    unloadChart() {
      this.barChart.dispose();
      this.pieChart.dispose();
    },
    drawBarChart() {
      let option = {
        title: {
          text: this.type === 'specificItem' ? "Specific Item" : "Trend",
          padding: [5, 10],
        },
        grid: {
          top: 40,
          left: 5,
          bottom: 0,
          right: 0,
          containLabel: true
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} <br/>{c} %",
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            formatter: "{value} %"
          }
        },
        yAxis: {
          type: 'category',
          data: []
        },
        series: [
          {
            name: "Sales",
            type: "bar",
            data: [],
          },
        ],
      };
      let total = 0;
      this.tableData.forEach(e => {
        total += e.cnt;
      })
      this.tableData.forEach((e) => {
        option.series[0].data.push({
          value: (100 * (e.cnt / total)).toFixed(2),
        });
        option.yAxis.data.push(e.name);
      });
      this.barChart.setOption(option, true);
    },
    drawPieChart() {
      let option = {
        title: {
          text: this.type === 'specificItem' ? "Specific Item" : "Trend",
          padding: [5, 10],
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} <br/>{c} %",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          top: "center",
          right: -10,
          data: [],
          reversed: true,
          selector: [
            {
              type: "all",
              title: this.$t("common.selectAll"),
            },
            {
              type: "inverse",
              title: this.$t("common.selectReverse"),
            },
          ],
        },
        series: [
          {
            name: "Sales",
            type: "pie",
            radius: "60%",
            top: 20,
            center: ["40%", "50%"],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            data: [],
          },
        ],
      };
      let total = 0;
      this.tableData.forEach(e => {
        total += e.cnt;
      })
      this.tableData.forEach((e) => {
        option.series[0].data.push({
          name: e.name,
          value: (100 * (e.cnt / total)).toFixed(2),
        });
        option.legend.data.push(e.name);
      });
      this.pieChart.setOption(option, true);
    },
    fetchOptionsYear(callback) {
      fetchOptionsYear(this.type).then(response => {
        if (response.success) {
          this.yearList = response.result.items || [];
          if (this.yearList.length > 0) {
            this.currentYear = this.yearList[0];
            this.fetchOptionsSeason(callback);
          }
        }
      })
    },
    fetchOptionsSeason(callback) {
      this.currentSeason = "";
      fetchOptionsSeason(this.type, { year: this.currentYear }).then(response => {
        if (response.success) {
          this.seasonList = response.result.items || [];
          if (this.seasonList.length > 0) {
            this.currentSeason = this.seasonList[0];
            this.fetchOptionsItem(callback);
          }
        }
      })
    },
    fetchOptionsItem(callback) {
      this.currentItem = "";
      fetchOptionsItem(this.type, {
        category: this.currentCategory,
        years: this.currentYear, seasons: this.currentSeason,
      }).then(response => {
        if (response.success) {
          this.itemList = response.result.items || [];
          if (this.itemList.includes("DRESS")) {
            this.currentItem = "DRESS";
          } else if (this.itemList.length > 0) {
            this.currentItem = this.itemList[0];
          }
          if (callback) {
            callback();
          }
        }
      })
    },
    fetchData() {
      fetchDashboard(this.type, {
        year: this.currentYear,
        season: this.currentSeason,
        sex: this.currentSex,
        category: this.currentCategory,
        item: this.currentItem
      })
        .then((response) => {
          if (response.success) {
            this.tableData = response.result.items || [];
            this.tableData.sort((a, b) => { return a.cnt - b.cnt });
            this.drawBarChart();
            this.drawPieChart();
          }
          this.chartLoading = false;
        })
        .catch(() => {
          this.chartLoading = false;
        });
    },
    changeYear() {
      this.fetchOptionsSeason();
    },
    changeSeason() {
      this.fetchOptionsItem();
    },
    changeCategory() {
      this.fetchOptionsItem();
    },
    clickDownload() {
      const loading = this.$loading({
        lock: true,
        text: this.$t("common.preparingData"),
      });
      let queryStr = new URLSearchParams({
        year: this.currentYear,
        season: this.currentSeason,
        sex: this.currentSex,
        category: this.currentCategory,
        item: this.currentItem
      }).toString();
      downloadFileAuth(
        aiApiRoot + "/v1/global-trends/dashboard/" + this.type + "/download?" + queryStr,
        "global-trend-dashboard-" +
        this.type +
        "-" +
        this.currentYear +
        "-" +
        this.currentSeason +
        "-" +
        this.currentSex +
        "-" +
        this.currentCategory +
        "-" +
        this.currentItem +
        ".xlsx",
        getToken(),
        () => {
          loading.close();
        }
      );
    }
  },
  computed: {
    type() {
      if (!this.$route.meta) {
        return null;
      }
      return this.$route.meta["type"];
    },
  }
};
</script>

<style lang="scss" scoped>
.global-trends-dashboard {
  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;

    .guide-button {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .search-content {
    background-color: #fff;
    padding: 10px 0 0 10px;
    margin-bottom: 10px;

    .el-form-item {
      margin-bottom: 10px;
    }

    .search-form {
      width: auto;

      .search-form-gutter {
        margin-right: 20px;
      }
    }
  }

  #item-dashboard-bar {
    width: 100%;
    min-height: 248px;
    height: calc(100vh - 100px - 52.5px - 52.5px - 10px - 30px);
    margin-top: 15px;
    margin-bottom: 15px;
  }

  #item-dashboard-pie {
    width: 100%;
    min-height: 248px;
    height: calc(100vh - 100px - 52.5px - 52.5px - 10px - 30px);
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
</style>